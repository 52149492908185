import { useEffect, useCallback, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useForm, useFormState, useUpdateEffect } from 'utils/hooks';
import { optionField, stringField } from 'common/utils/form/fieldTypes';

import DepartmentsActions from './DepartmentsActions';
import WhoFillTheForms from '../components/WhoFillTheForms';
import Textarea from '@/ts-common/components/form/inputs/Textarea';
import ShadowBox from 'common/components/general/ShadowBox';
import ApproveButton from './ApproveButton';
import RejectButton from './RejectButton';

import PageSaving from 'common/components/general/PageSaving';
import Form from './Form';

import {
  selectActiveEntityId,
  selectReviewProcessId
} from 'common/components/review-process/store/selectors';
import xIcon from 'common/assets/svg/common/x.svg';
import _debounce from 'lodash/debounce';
import DangerousActionModal from 'common/components/modals/DangerousActionModal';
import SvgRender from 'common/components/general/SvgRender';
import {
  updateReviewProcessSubstepRemarks,
  approveReviewProcessSubstep,
  removeReviewProcessSubstep,
  updateReviewProcessSubstepActions,
  revokeReviewProcessSubstep,
  rejectReviewProcessStep,
  getEntityReviewProcess
} from 'common/components/review-process/store/actions';
import { useAppDispatch } from '@/store/hooks';
import entityActions from 'common/components/review-process/entity-actions';

export const config = {
  who_fill_the_forms: stringField({ initialValue: 'departments' }),
  departments: optionField(),
  department_roles: optionField(),
  eligible_departments: optionField(),
  eligible_department_roles: optionField(),
  authorized_departments: optionField(),
  authorized_department_roles: optionField(),
  remarks: stringField()
};

const SubStep = ({
  subStep,
  onFormSelect,
  loading,
  disabled,
  isDynamic,
  entityId,
  refetchDrawer,
  index,
  stepId,
  stepIndex,
  isRequired,
  steps
}) => {
  const {
    can_approve,
    can_reject,
    can_revoke,
    departments,
    department_roles,
    has_remarks,
    id,
    completed_by,
    text_after_completion,
    text_before_completion,
    can_edit_reviewer
  } = subStep;
  const activeEntityId = useSelector(selectActiveEntityId);
  const reviewProcessId = useSelector(state => selectReviewProcessId(state, activeEntityId));
  const dispatch = useAppDispatch();

  const { formState, loadValues, collectValues } = useForm(config, {});
  const { fields, selectField, setFieldError } = useFormState(formState);

  const [isAutoSaving, setIsAutoSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const [modal, setModal] = useState({ isOpen: false, type: null, extraParams: null });

  const isDisabled = useMemo(
    () => loading || isLoading || disabled,
    [loading, isLoading, disabled]
  );

  const handleApprove = async () => {
    try {
      if (completed_by) {
        setIsAutoSaving(true);

        await dispatch(revokeReviewProcessSubstep({ substep_id: id }));
      } else {
        setIsAutoSaving(true);

        await dispatch(approveReviewProcessSubstep({ substep_id: id }));
      }

      await refetchDrawer();

      setIsAutoSaving(false);
    } catch (error) {
      setIsAutoSaving(false);
    }
  };

  const handleReject = async params => {
    const { start_from_step_id } = params;
    if (!reviewProcessId) return;

    try {
      setIsAutoSaving(true);

      await dispatch(
        rejectReviewProcessStep({
          start_from_step_id,
          step_id: stepId,
          review_process_id: reviewProcessId
        })
      );
      await refetchDrawer();

      setIsAutoSaving(false);
    } catch (error) {
      setIsAutoSaving(false);
    }
  };

  const modalHandler = async params => {
    setModal(params);
  };

  const onRemove = async () => {
    try {
      setIsLoading(true);

      await dispatch(removeReviewProcessSubstep({ step_id: stepId, substep_id: id }));
      await refetchDrawer();

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const onSaveSelectedActions = async () => {
    const values = collectValues();

    if (!values) return;

    const params = {
      step_id: stepId,
      substep_id: id,
      department_ids:
        values.who_fill_the_forms === 'departments' ? values.departments?.map(d => d) || [] : [],
      department_role_ids:
        values.who_fill_the_forms === 'roles' ? values.department_roles?.map(r => r) || [] : []
    };

    try {
      setIsAutoSaving(true);

      await dispatch(updateReviewProcessSubstepActions(params));

      if (entityId) {
        await dispatch(
          getEntityReviewProcess({
            entity_id: entityId,
            review_process_action: entityActions.purchasingRequisitionApproval.label
          })
        );
      }

      setIsAutoSaving(false);
    } catch (e) {
      setIsAutoSaving(false);
    }
  };

  const onSaveRemarks = useCallback(
    async params => {
      try {
        setIsAutoSaving(true);

        await dispatch(updateReviewProcessSubstepRemarks({ ...params, entityId: entityId }));

        setIsAutoSaving(false);
      } catch (e) {
        setIsAutoSaving(false);
      }
    },
    [entityId, dispatch]
  );

  const debouncedAutoSaveSelectedActions = _debounce(onSaveSelectedActions, 800);

  const loadInitialValues = () => {
    const {
      departments,
      department_roles,
      remarks,
      eligible_departments,
      eligible_department_roles,
      authorized_departments,
      authorized_department_roles,
      ...rest
    } = subStep;

    loadValues({
      ...rest,
      who_fill_the_forms:
        eligible_departments?.length || departments?.length ? 'departments' : 'roles',
      departments: departments?.map(department => department.id) || [],
      department_roles: department_roles?.map(role => role.id) || [],
      eligible_departments: eligible_departments?.map(department => department.id) || [],
      eligible_department_roles: eligible_department_roles?.map(role => role.id) || [],
      authorized_departments: authorized_departments?.map(department => department.id) || [],
      authorized_department_roles: authorized_department_roles?.map(role => role.id) || [],
      remarks: remarks || ''
    });
    setIsInitialized(true);
  };

  useEffect(() => {
    if (!isInitialized) {
      /* Do not use the drawerIsOpen to re-initialize the substep. 
        The Step/Substep components can be used in any place, regardless if it is a drawer or not. */
      loadInitialValues();
    }
  }, [isInitialized]);

  useUpdateEffect(() => {
    if (!isAutoSaving && !subStep?.remarks) {
      /* When a substep is rejected, its remarks are wiped out */
      selectField('remarks')('');
    }
  }, [isAutoSaving, subStep?.remarks]);

  const constructBodyModal = useCallback(() => {
    if (modal.type === 'delete') {
      return <div>Are you sure you want to delete this step?</div>;
    } else {
      if (modal.extraParams?.isFirstStep) {
        return (
          <div>
            Are you sure you want to reject <span className="fw-bold">Step {stepIndex + 1}</span>{' '}
            and start from the beginning of the Process?
          </div>
        );
      } else {
        return (
          <div>
            Are you sure you want to reject&nbsp;
            <span className="fw-bold">Step {stepIndex + 1}</span>
            &nbsp;and start from the&nbsp;
            <span className="fw-bold">Step {modal.extraParams?.start_from_step_index}</span> ?
          </div>
        );
      }
    }
  }, [modal.type, modal.extraParams, stepIndex]);

  const bodyModal = useMemo(() => constructBodyModal(), [constructBodyModal]);

  return (
    <>
      <ShadowBox flat className={`cpx-12 cpy-12 ${index !== 0 ? 'mt-1' : ''}`}>
        <PageSaving isSaving={isAutoSaving} />
        {!isDynamic ? (
          <DepartmentsActions departments={departments} departmentRoles={department_roles} />
        ) : (
          <WhoFillTheForms
            areRadiosDisabled
            fields={fields}
            selectField={selectField}
            setFieldError={setFieldError}
            disabled={isLoading || completed_by?.id || !can_edit_reviewer}
            options={
              subStep.eligible_departments?.length
                ? subStep.eligible_departments
                : subStep.eligible_department_roles?.length
                ? subStep.eligible_department_roles
                : undefined
            }
            onRemove={
              index !== 0 && !completed_by?.id
                ? () => modalHandler({ isOpen: true, type: 'delete' })
                : null
            }
            hasAutoSave={() => debouncedAutoSaveSelectedActions()}
            activeId={entityId}
          />
        )}
        {has_remarks ? (
          <Textarea
            label="Remarks"
            placeholder="Add some text"
            readOnly={isDisabled || !can_approve}
            onChange={e => selectField('remarks')(e.target.value)}
            onAutoSave={remarks => onSaveRemarks({ substep_id: subStep.id, remarks })}
            rows={4}
            {...fields.remarks}
          />
        ) : null}
        {subStep.substep_forms.map((substepForm, index) => (
          <div
            key={substepForm?.id}
            className={`${index === subStep.substep_forms?.length - 1 ? 'cmb-12' : ''} `}
          >
            <Form substepForm={substepForm} onFormSelect={onFormSelect} subStepId={id} />
          </div>
        ))}
        <div className="d-flex align-items-center justify-content-end">
          <RejectButton
            modalHandler={modalHandler}
            disabled={isDisabled || !can_reject}
            setModal
            currentStepIndex={stepIndex}
            steps={steps}
          />
          <ApproveButton
            handleApprove={handleApprove}
            disabled={isDisabled}
            completedBy={completed_by?.full_name || ''}
            textBeforeCompletion={text_before_completion}
            textAfterCompletion={text_after_completion}
            canApproveStep={can_approve}
            canRevokeApprovedStep={can_revoke}
            isCompleted={!!completed_by}
          />
        </div>
      </ShadowBox>

      <DangerousActionModal
        transparent
        action={'delete'}
        actionIcon={
          modal.type === 'delete' ? null : (
            <SvgRender src={xIcon} style={{ width: 60, height: 60 }} className="text-red" />
          )
        }
        onAccept={() => (modal.type === 'delete' ? onRemove() : handleReject(modal.extraParams))}
        closeModal={() => modalHandler({ isOpen: false, type: null, extraParams: null })}
        isOpen={modal.isOpen}
        actionText={modal.type === 'delete' ? 'DELETE' : 'REJECT'}
        header={`${modal.type === 'delete' ? 'Delete' : 'Reject'} step`}
        body={bodyModal}
      />
    </>
  );
};

export default SubStep;
