import TYPES from './types';
import { constructEntities } from './helpers';
import _get from 'lodash/get';
import _cloneDeep from 'lodash/cloneDeep';
import _pickBy from 'lodash/pickBy';

const INITIAL_STATE = {
  entities: {},
  activeEntityId: null,
  isDrawerOpen: false,
  isFormDrawerOpen: false,
  createdBy: null,
  createdAt: null,
  notes: []
};

const reviewProcess = function (state = _cloneDeep(INITIAL_STATE), { type, payload }) {
  switch (type) {
    case TYPES.GET_ENTITY_REVIEW_PROCESS.START:
      if (state.activeEntityId === payload.params?.entity_id) {
        return state;
      }

      return _cloneDeep(INITIAL_STATE);

    case TYPES.GET_ENTITY_REVIEW_PROCESS.SUCCESS:
    case TYPES.CREATE_ENTITY_REVIEW_PROCESS.SUCCESS:
      if (!payload?.entity_id) return state;

      return {
        ...state,
        createdBy: payload.created_by,
        createdAt: payload.created_at,
        entities: constructEntities(state.entities, payload?.entity_id, payload)
      };

    case TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_ACTIONS.SUCCESS: {
      const stepId = _get(payload, 'review_process_step_id');
      const subStepId = _get(payload, 'id');
      const activeEntityId = _get(state, 'activeEntityId');

      if (!activeEntityId) return state;

      const updatedSteps =
        state.entities?.[activeEntityId]?.steps?.map(step =>
          stepId === step?.id
            ? {
                ...step,
                substeps: step?.substeps?.map(substep =>
                  subStepId === substep?.id ? { ...substep, ...payload } : substep
                )
              }
            : step
        ) || [];

      return {
        ...state,
        entities: {
          ...state.entities,
          [activeEntityId]: {
            ...(state.entities?.[activeEntityId] || {}),
            steps: updatedSteps
          }
        }
      };
    }

    case TYPES.UPDATE_REVIEW_PROCESS_SUBSTEP_REMARKS.SUCCESS: {
      const responseData = _get(payload, 'data', {});
      const entityId = _get(payload, 'entityId');

      if (!entityId) return state;

      return {
        ...state,
        entities: {
          ...state.entities,
          [entityId]: {
            ...state.entities[entityId],
            steps: (state.entities[entityId]?.steps || []).map(step =>
              step?.id === responseData?.review_process_step_id
                ? {
                    ...step,
                    substeps: (step?.substeps || [])?.map(substep =>
                      substep?.id === responseData?.id ? { ...substep, ...responseData } : substep
                    )
                  }
                : step
            )
          }
        }
      };
    }

    case TYPES.GET_ENTITY_REVIEW_PROCESS.ERROR:
    case TYPES.CREATE_ENTITY_REVIEW_PROCESS.ERROR:
    case TYPES.CHECK_IF_REVIEW_PROCESS_IS_ENABLED.ERROR:
      return {
        ...state,
        isDrawerOpen: state.isDrawerOpen,
        isFormDrawerOpen: state.isFormDrawerOpen
      };

    case TYPES.CHECK_IF_REVIEW_PROCESS_IS_ENABLED.SUCCESS:
      return {
        ...state,
        entities: {
          ...state.entities,
          [payload?.entity_id]: {
            ...state.entities[payload?.entity_id],
            isEnabled: payload.can_create
          }
        }
      };

    case TYPES.SET_REVIEW_PROCESS_DRAWER_IS_OPEN:
      return {
        ...state,
        activeEntityId: payload?.entityId || null,
        isDrawerOpen: payload?.data
      };

    case TYPES.SET_ACTIVE_REVIEW_PROCESS_ENTITY_ID:
      return {
        ...state,
        activeEntityId: payload
      };

    case TYPES.RESET_ACTIVE_ENTITY_REVIEW_PROCESS:
      return {
        ...state,
        activeEntityId: null,
        entities: _pickBy(state.entities, (_, key) => +key !== +payload)
      };

    case TYPES.SET_REVIEW_PROCESS_FORM_DRAWER_IS_OPEN:
      return {
        ...state,
        isFormDrawerOpen: payload?.data
      };

    case 'GET_REVIEW_PROCESS_NOTES/fulfilled':
      return {
        ...state,
        notes: payload || []
      };

    default:
      return state;
  }
};

export default reviewProcess;
